// Load Inter font
@use "~inter-ui/default" as inter-ui with (
  $inter-font-path: "~inter-ui/Inter (web latin)"
);
@include inter-ui.weight-400-normal;
@include inter-ui.weight-500-normal;
@include inter-ui.weight-600-normal;

// Load normalize.css
@import-normalize;

// Prevent elements growth from margins and paddings
* {
  box-sizing: border-box;
}
